import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import BreadCrumbs from "../components/breadCrumbs"
import Head from '../components/head'

const ThankYouPage = ({ data }) => {
    const thankyou = data.UI.dsThankYou
    
    return (
        <Layout>
            <Head SEO_Tags={thankyou.SeoTag} />            
            <section className="hero_services tB-padd-30" style={{backgroundImage: thankyou.Banner.Inner_BackgroundImage ? "url("+thankyou.Banner.Inner_BackgroundImage.url+")" : null }}>
                <BreadCrumbs crumbs={thankyou.Title} textColor={'#072836'} />
                <div className="container">
                    <div className="contact_hero_content py-5">
                        {thankyou.Banner.Inner_Title ? (<h2 dangerouslySetInnerHTML={{ __html: thankyou.Banner.Inner_Title }} />) : null}
                        {thankyou.Banner.Inner_ShortDescription ? (<p dangerouslySetInnerHTML={{ __html: thankyou.Banner.Inner_ShortDescription }} />) : null}
                    </div>                    
                </div>
            </section>
        </Layout>
    )
}

export default ThankYouPage

export const query = graphql`
  query {
    UI {
        dsThankYou {
            Title
            Description
            Banner {
              Inner_BackgroundImage {
                url
                alternativeText
              }
              Inner_Title
              Inner_ShortDescription
              Inner_FontColor
              CTALink {
                Button_Name
                Button_URL
              }
            }
            SeoTag {
                Meta_Title
                Meta_Description
            }
        }  
    }
  }
`